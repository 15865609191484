.nav {
  z-index: 10;
  background-color: white;
  min-width: 260px;
  min-height: calc(100vh - 78px);
  border-right: 1px solid #e0e0e0;
  box-sizing: border-box;
  padding: 20px 0;
}

.nav a {
  text-decoration: none;
}

.nav__item {
  color: rgba(0, 0, 0, 0.7);
  padding: 10px 20px;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  cursor: pointer;
}

.nav__item--selected, .nav__item:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.nav__item-icon {
  height: 24px;
  width: 24px;
  margin-right: 10px;
  background-size: contain;
  background-repeat: no-repeat;
}

@media screen and (max-width: 48em) {
  .nav {
    position: absolute;
    top: 78px;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
  }

  .nav--closed {
    display: none;
  }
}

@media print {
  .nav {
    display: none;
  }
}
